import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Logo from "../../assets/other/logo.svg"
import Facebook from "../../assets/other/facebook.svg"
import Youtube from "../../assets/other/youtube.svg"
import { breakpoints } from "../../consts"
import LanguageSwitcher from "./LanguageSwitcher"
import { Link } from "gatsby"

const Wrapper = styled.header<{ transparent: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0;
    height: 60px;
    padding: 0 20px;
    z-index: 999;
    background-color: ${({ transparent }) =>
        transparent ? "transparent" : "#2b2b2b"};
    transition: 0.3s ease-in-out;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 20px;
        width: calc(100% - 40px);
        height: 1px;
        background-color: rgba(255, 255, 255, 0.2);

        ${breakpoints.md} {
            left: 50px;
            width: calc(100% - 100px);
        }
    }

    ${breakpoints.md} {
        padding: 0 50px;
        height: 80px;
    }
`

const LogoWrapper = styled.div`
    width: 100px;

    ${breakpoints.md} {
        width: 150px;
    }

    svg {
        color: #fff;
    }
`

const Icons = styled.div`
    margin-left: auto;
    display: flex;
    height: 100%;

    svg {
        width: 16px;
    }
`

const Icon = styled.a<{ background: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    &:hover {
        background-color: ${({ background }) => background};
    }
`

const Header = () => {
    const [transparent, setTransparent] = useState(true)
    useEffect(() => {
        const calcHeight = () => {
            const currentScroll = window.pageYOffset

            if (currentScroll > 0) {
                setTransparent(false)
            } else {
                setTransparent(true)
            }
        }
        window.addEventListener("scroll", calcHeight)
        return () => {
            window.removeEventListener("scroll", calcHeight)
        }
    }, [])
    return (
        <Wrapper transparent={transparent}>
            <Link to="/">
                <LogoWrapper>
                    <Logo />
                </LogoWrapper>
            </Link>
            <Icons>
                <Icon
                    target="_blank"
                    rel="noopener"
                    background="#3b5998"
                    href="https://www.facebook.com/Deadline24pl"
                >
                    <Facebook />
                </Icon>
                <Icon
                    target="_blank"
                    rel="noopener"
                    background="#ff0000"
                    href="https://www.youtube.com/channel/UCXQ1gyZTgWYkL1PWrX3jiQQ"
                >
                    <Youtube />
                </Icon>
            </Icons>

            <LanguageSwitcher />
        </Wrapper>
    )
}

export default Header
