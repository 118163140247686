import React from "react"
import { useLanguage } from "../../context/LanguageContext"
import styled from "styled-components"

const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
    height: 100%;
    overflow: hidden;
    letter-spacing: 0.1em;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    &:after {
        transition: ease-in-out 0.2s;

        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #f26722;
        transform: translateY(100%);
        z-index: -1;
    }

    &:hover {
        &:after {
            transform: translateY(0);
        }
    }
`

const Language = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 100%;
    padding: 20px;
`

const LanguageSwitcher = () => {
    const { language, setLanguage } = useLanguage()
    return (
        <Wrapper>
            {language === "pl" ? (
                <Language onClick={() => setLanguage("en")}>EN</Language>
            ) : (
                <Language onClick={() => setLanguage("pl")}>PL</Language>
            )}
        </Wrapper>
    )
}

export default LanguageSwitcher
