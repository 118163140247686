import React from "react"
import styled from "styled-components"
import Header from "./header/Header"
import Footer from "./Footer"
import Video from "../components/Video"
import { breakpoints } from "../consts"

const Main = styled.main`
    position: relative;
    padding: 100px 20px 0 20px;
    background-color: #2b2b2b;
    overflow: hidden;

    ${breakpoints.md} {
        padding: 150px 20px 250px 20px;
        background-color: transparent;
    }

    ${breakpoints.hd} {
        min-height: 90vh;
    }
`

const Template = ({ children }) => {
    return (
        <>
            <Header />
            <Main>
                <Video />
                {children}
            </Main>
            <Footer />
        </>
    )
}

export default Template
