import * as React from "react"
import Template from "../templates/Template"
import styled from "styled-components"
import { useLanguage } from "../context/LanguageContext"
import { breakpoints } from "../consts"

const Content = styled.p<{ right?: boolean }>`
    display: block;
    font-size: 18px;
    letter-spacing: 0.1em;
    line-height: 1.6em;
    padding-bottom: 30px;
    margin: 0 auto;
    text-align: ${({ right }) => (right ? "right" : "left")};
    font-weight: 500;

    ${breakpoints.md} {
        width: 85%;
    }
`

const Homepage = () => {
    const { language } = useLanguage()
    return (
        <Template>
            {language === "pl" ? (
                <div>
                    <Content>
                        Rywalizować z najlepszymi algorytmikami z całego świata,
                        tworzyć strategie działań i zaciekle walczyć przez 24
                        godziny. Eksplorować wszechświaty wykreowane w zadaniach
                        i poznawać industrialny charakter Śląska. Badać granice
                        własnej wytrzymałości i poszerzać horyzonty
                        programistycznej wiedzy. Wzajemnie się wspierać,
                        wygrywać i wspólnie kreować niezapomniane chwile.
                    </Content>
                    <Content right>– Takie były cele Deadline24.</Content>
                    <Content>
                        Informujemy, że w 2018 roku odbyła się ostatnia edycja
                        maratonu. Nie planujemy jego organizacji w kolejnych
                        latach – żegnamy się z konkursem. Tworzenie takiego
                        wydarzenia było wspaniałą przygodą. Dziękujemy, że razem
                        z nami pisaliście jego historię.
                    </Content>
                    <Content right>Zespół Deadline24</Content>
                </div>
            ) : (
                <div>
                    <Content>
                        To compete with the best algorithmic enthusiasts from
                        around the world, create strategies and passionately
                        fight for 24 hours. To explore universes appearing in
                        the contest tasks and learn about the industrial
                        heritage of the Silesia Province. To examine personal
                        limits of endurance and broaden horizons of programming
                        knowledge. To support each other, win, and create
                        unforgettable moments together.
                    </Content>
                    <Content right>
                        – These were the goals of Deadline24.
                    </Content>
                    <Content>
                        The 2018 edition of the marathon was the final one. We
                        do not intend to organize it next years – it's time to
                        say goodbye. Being part of this event was an amazing
                        adventure. Thank you all for writing its history with
                        us.
                    </Content>
                    <Content right>Deadline24 team</Content>
                </div>
            )}
        </Template>
    )
}

export default Homepage
