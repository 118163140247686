import React from "react"
import styled from "styled-components"
import { breakpoints } from "../consts"
import Logo from "../assets/other/logo.svg"
import LogoFp from "../assets/images/logofp.png"

const Wrapper = styled.footer`
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    background-color: #2b2b2b;
    font-size: 14px;
    text-transform: uppercase;
    color: #939393;
    font-weight: 300;

    ${breakpoints.md} {
        padding: 50px;
    }
`

const LogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    svg {
        width: 150px;
    }
`

const Slogan = styled.div`
    margin-left: 20px;
`

const Fp = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 150px;
    }
`

const Footer = () => {
    return (
        <Wrapper>
            <LogoWrapper>
                <Logo />
                <Fp>
                    <Slogan>powered by</Slogan>
                    <a href="https://kariera.future-processing.pl/">
                        <img src={LogoFp} />
                    </a>
                </Fp>
            </LogoWrapper>
        </Wrapper>
    )
}

export default Footer
