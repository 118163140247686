import React from "react"
import styled from "styled-components"
import videoSrc from "../assets/other/banner_bg.mp4"
import Background from "../assets/other/circle_bg.svg"

const Wrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    overflow: hidden;
`

const VideoPlayer = styled.video`
    width: 100%;
    height: 100%;
`

const BackgroundWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
`

const Video = () => {
    return (
        <Wrapper>
            <VideoPlayer muted autoPlay loop>
                <source src={videoSrc} type="video/mp4" />
            </VideoPlayer>
            <BackgroundWrapper>
                <Background />
            </BackgroundWrapper>
        </Wrapper>
    )
}

export default Video
